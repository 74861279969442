import React from 'react'
import styled from 'styled-components'
import Text from '../Text/Text';

const Container = styled.div`
    margin-bottom: 2rem;
`;

const TextArea = styled.textarea`
    width: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    padding: 0.8rem 0.7rem;
    vertical-align: middle;
    font-size: 1.125rem;
    line-height: 1.42857;
    outline: none;
    transition: border 0.4s ease-in-out;

    &:hover{
        border: 0.5px solid rgba(255, 255, 255, 1);
    }
`;


const Description = ({ title, handleChange, value, required }) => {
    return (
        <Container>
            <label>
                <Text text={title} variant='p2' />
            </label>
            <br />
            <TextArea cols='100' name='description' onChange={handleChange} value={value} required={required} />
        </Container>
    )
}

export default Description