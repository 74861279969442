import React, { useContext } from 'react'
import styled from 'styled-components'
import { GlobalContext } from '../../context/GlobalContext';
import { Link } from 'react-router-dom';
import useHelper from '../../helper/useHelper';

const NavBar = styled.nav`
    max-width: 2000px;
    width: 90%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
`;

const Container = styled.div`
    min-height: 4rem;
    max-width: 2000px;
    width: 100%;
    background-color: #000;
    align-items: center;
    /* padding-left: 4%;
    padding-right: 4%; */
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 10;
    border-bottom: 1px solid #262626;

    .listItem {
        display: none;
    }

    a {
        text-decoration: none;
        color: #fff;
    }

    @media screen and (min-width: 480px) {
        /* padding-left: 7%;
        padding-right: 7%; */
    }

    @media screen and (min-width: 767px) {
        min-height: 5rem;
    }
    
    @media screen and (min-width: 991px) {
        .listItem {
            display: block;
        }
    }
`;

const Wrapper = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
`;

const Logo = styled.span`
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
`;

export const List = styled.li`
    list-style: none;
    padding-left: 4rem;
    cursor: pointer;
    opacity: ${prop => (!prop.active) ? 0.4 : prop.active};
    transition: opacity 0.4s ease-in-out;

    &:hover,
    &:active {
        opacity: 1;
    }
`;

const MenuButton = styled.div`
    width: 27px;
    min-height: 17px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    div {
        width: 100%;
        height: 2px;
        background-color: #000;
        transition: transform 0.3s, opacity 0.3s;
    }

    .line-1 {
        transform: ${({ isMenuOpen }) => (isMenuOpen ? 'rotate(45deg) translate(6px, 6px)' : 'none')};
        background-color: #fff;
    }

    .line-2 {
        transform: ${({ isMenuOpen }) => (isMenuOpen ? 'rotate(-45deg) translate(4px, -4px)' : 'none')};
        background-color: #fff;
    }

    .menuItem {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 3.7rem;
        margin-bottom: 3.7rem;
        padding: 1rem 0;
        font-family: Archia webfont, sans-serif;
        font-size: 3rem;
        font-weight: 400;
        display: flex;
        opacity: 1;
    }

    @media screen and (min-width: 991px) {
        .line-1,
        .line-2 {
            display: none;
        }
    }
`;

export const MenuMobile = styled.div`
    position: fixed;
    height: 100vh;
    background-color: black;
    top: ${({isMenuOpen}) => (isMenuOpen ? '4rem' : '-100%')};
    left: 0;
    z-index: 5;
    right: 0;
    transition: top 0.4s ease-in-out;

    .menuItem {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 3.7rem;
        margin-bottom: 3.7rem;
        padding: 1rem 0;
        font-family: Archia webfont, sans-serif;
        font-size: 3rem;
        font-weight: 400;
        display: flex;
        transition: opacity 0.4s ease-in-out;
    }

    a {
        text-decoration: none;
        color: white;
    }
`;


const Navbar = () => {
    const { isMenuOpen, setIsMenuOpen, selectedWorkRef, isSelectedWorkVisible } = useContext(GlobalContext);
    const { scrollToDiv } = useHelper();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };


    return (
        <NavBar>
            <Container>
                <Wrapper> 
                    <Logo>soyas.</Logo>
                    <MenuButton isMenuOpen={isMenuOpen} onClick={toggleMenu}>
                        <div className="line-1"></div>
                        <div className="line-2"></div>
                    </MenuButton>
                    <div className='listItem'>
                        <ul style={{display:'flex',alignItems:'center'}}>
                            <List active={1}><Link to="/">Home</Link></List>
                            <List active={isSelectedWorkVisible}><Link onClick={() => scrollToDiv(selectedWorkRef)}>Work</Link></List>
                            {/* <List><Link to="about">About</Link></List> */}
                        </ul>
                    </div>
                </Wrapper>
            </Container>

            <MenuMobile isMenuOpen={isMenuOpen}>
                <List className='menuItem active' active={1}><Link to="/" onClick={toggleMenu}>Home</Link></List>
                <List className='menuItem' onClick={() => scrollToDiv(selectedWorkRef)}><Link to="#" onClick={toggleMenu}>Work</Link></List>
                {/* <List className='menuItem'><Link to="about" onClick={toggleMenu}>About</Link></List> */}
            </MenuMobile>
        </NavBar>
    )
}

export default Navbar