import React from 'react';
import './index.css'; // Make sure to adjust the file name according to your project structure
import styled from 'styled-components';

const Heading = styled.div`
  font-size: ${prop => (prop.variant == 'hero') ? '3rem' : '2rem'};
  font-family: Archia webfont, sans-serif;
  margin-bottom: ${prop => prop.marginBottom};
  background-color: rgba(0,0,0,0);

  @media screen and (min-width: 479px) {
    font-size: ${prop => (prop.variant == 'hero') ? '4rem' : '2rem'};
  }

  @media screen and (min-width: 767px) {
    font-size: ${prop => (prop.variant == 'hero') ? '5rem' : '2.5rem'};
    font-weight: bold
  }

  @media screen and (min-width: 767px) {
    font-size: ${prop => (prop.variant == 'hero') ? '5.625rem' : '3rem'};
    font-weight: 400;
    line-height: 1;
  }
`;

const SpaceHolder = styled.div`
  /* min-height: 150px; */
  min-height: ${(prop) => prop.spaceholder};
  
  @media screen and (min-width: 767px) {

  }
`;


const AnimateSlideUpText = ({ text, delay, variant, marginBottom, visible, spaceholder='200px' }) => {  
  if (visible)
    return (
      <Heading className="heading" variant={variant} marginBottom={marginBottom}>
        <div>
          <p className="slide-up" style={{ '--delay': `${delay}s` }}>
            <span className="green">{text}</span>
          </p>
        </div>
      </Heading>
    );
  else 
  return (
    <SpaceHolder spaceholder={spaceholder}></SpaceHolder>
  );
};

export default AnimateSlideUpText;
