import { useEffect } from "react";
import Navbar from "./component/Navbar/Navbar";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import About from "./pages/About";
import Footer from "./component/Footer/Footer";

function App() {
    useEffect(() => {
        document.title = "Portfolio soyas.";
    }, []);

    return (
        <div style={{backgroundColor:'black',position:'relative',zIndex:'10'}}>
            <Router>
                <Navbar />
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                </Routes>
            </Router>
            <Footer />
        </div>
    );
}

export default App;
