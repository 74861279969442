import React from 'react'

const useHelper = () => {
    // Function to scroll to the target div
    const scrollToDiv = (ref) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('Ref is null or not initialized');
        }
    };

    return { scrollToDiv }
}

export default useHelper