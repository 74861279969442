import React, { useState } from 'react'
import styled from 'styled-components'
import Text from '../Text/Text';


const Container = styled.div`
    margin-bottom: 2rem;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

const Box = styled.div`
    width: 1.2rem;
    height: 1.2rem;
    color: #fff;
    border: 1px solid #fff;
    margin-right: 1rem;

    &.check {
        background-color: #fff;
    }
`;


const CheckBox = ({ title, lists, checkedLists, setCheckedLists }) => {
    const toggleList = (list) => {
        setCheckedLists(prevState => {
            const newState = { ...prevState };
            newState[list] = !newState[list];
            return newState;
        });
    };

    return (
        <Container>
            <label>
                <Text
                    text={title}
                    variant='p2'
                />
                <br />
            </label>
            {lists.map((list,key) => (
                <Wrapper key={key}>
                    <Box className={checkedLists[list] ? 'check' : ''} onClick={() => toggleList(list)} />
                    <Text text={list} variant='p2' />
                </Wrapper>
            ))}
        </Container>
    )
}

export default CheckBox