import React from 'react'
import styled from 'styled-components'
import Text from '../Text/Text';

const Container = styled.div`

`;

const DropDown = styled.div`
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    transition: border 0.4s ease-in-out;

    &:hover{
        border: 0.5px solid rgba(255, 255, 255, 1);
    }
`;

const ListBox = styled.select`
    height: 4rem;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 9px;
    display: flex;
    outline: none;
    border: none;
    width: 100%;
    padding: 0.8rem 0.7rem;
`;

const List = styled.option`
    background-color: black;
    padding: 0.8rem 0.7rem;
    color: #fff;
`;


const SelectBox = ({ title, lists, budget, setBudget }) => {
    return (
        <Container>
            <label>
                <Text variant='p2' text={title} />
                <br />
            </label>            
            <DropDown>
                <ListBox onChange={(e) => setBudget(e.target.value)} value={budget}>
                    <List>Select One...</List>
                    {lists.map((list,key) => <List key={key}>{list}</List>)}
                </ListBox>
            </DropDown>
        </Container>
    )
}

export default SelectBox