import React from 'react'
import Testinomials from '../component/Testinomials/Testinomials'

const About = () => {
    return (
        <div>About
            {/* <Testinomials /> */}
        </div>
    )
}

export default About