import React, { useState } from 'react'
import TextBox from '../TextBox/TextBox';
import CheckBox from '../CheckBox/CheckBox';
import Description from '../Description/Description';
import SelectBox from '../SelectBox/SelectBox';
import Button from '../Button/Button';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

const Form = styled.form`
    margin-bottom: 5rem;
    @media screen and (min-width: 1400px) {

        margin-left: 9rem;
    }
`;

const FormContainer = () => {
    const [textBoxValue, setTextBoxValue] = useState({
        fullName: '',
        email: '',
        businessName: '',
        description: '',
    });
    const [checkedLists, setCheckedLists] = useState([]);
    const [budget, setBudget] = useState('');
    const [isLoading, setLoading] = useState({
        spinner: false,
        sent: false,
        error: false,
    });
    const [message, setMessage] = useState({
        errorMessage: '',
        sentMessage: '',
    });

    const handleSubmit = async (e) => {
        try {
            setMessage({
                sentMessage: "",
                errorMessage: '',
            });

            e.preventDefault();
            const data = {
                "service_id": "service_lzrnlwj",
                "template_id": "template_t904jox",
                "user_id": "2FDzqm8fl_0uMqp6h",
                "template_params": {
                    "to_name": "Soyas",
                    "from_name": textBoxValue.fullName,
                    "from_email": textBoxValue.email,
                    "business_name": textBoxValue.businessName,
                    "description": textBoxValue.description,
                    "services": Object.keys(checkedLists),
                    "budget": budget
                }
            };

            // start spinner 
            setLoading(prevState => ({
                ...prevState,
                spinner: true,
            }));


            const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            // stop spinner
            setLoading(prevState => ({
                ...prevState,
                spinner: false,
            }));
            if (response.status == 200) {
                // show spinner
                setMessage(prevState => ({
                    ...prevState,
                    sentMessage: 'Message sent successfully. Thank you!'
                }));
            } else {
                // something went wrong try again
                setMessage(prevState => ({
                    ...prevState,
                    errorMessage: 'Error: Something went wrong. Try again!'
                }));
            }
        } catch (error) {
            console.error(error);
            setMessage(prevState => ({
                ...prevState,
                errorMessage: 'Error: Something went wrong. Try again!'
            }));
        }
    }

    const handleChange = (e) => {
        // clear message
        setMessage({
            errorMessage: '',
            sentMessage: ''
        });
        
        const { name, value } = e.target;
        switch(name) {
            case "Full Name":
                setTextBoxValue(prevState => ({
                    ...prevState,
                    fullName: value
                }));
                break;
            case "Email":
                setTextBoxValue(prevState => ({
                    ...prevState,
                    email: value
                }));
                break;
            case "Business Name":
                setTextBoxValue(prevState => ({
                    ...prevState,
                    businessName: value
                }));
                break;
            case "description":
                setTextBoxValue(prevState => ({
                    ...prevState,
                    description: value
                }));
                break;
        }
    }


    return (
        <Form onSubmit={handleSubmit}>
            <TextBox title='Full Name *' handleChange={handleChange} value={textBoxValue.fullName} required={true} />
            <TextBox title='Email *' handleChange={handleChange} value={textBoxValue.email} required={true}/>
            <TextBox title='Business Name *' handleChange={handleChange} value={textBoxValue.businessName} required={true} />
            <CheckBox
                title='How may I help you? *'
                lists={['Branding', 'Web Design', 'Website Development']}
                checkedLists={checkedLists}
                setCheckedLists={setCheckedLists}
            />
            <Description
                title='Tell me a bit about your project (your business mission and goals you wish to achieve) *'
                handleChange={handleChange}
                value={textBoxValue.description}
                required={true}
            />
            <SelectBox
                title='Budget'
                lists={['< $1,000', '$1,000 - $2,000', '$3,000 - $5,000', 'Any amount it may cost']}
                budget={budget}
                setBudget={setBudget}
            />
            {isLoading.spinner ? <div style={{ margin:"3.8rem 0"}}>
                    <ClipLoader
                        color='#fff'
                        loading={isLoading.spinner}
                        size={35}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div> 
                : <Button 
                    variant='secondary'
                    text='Submit'
                /> 
            }
            {message.sentMessage}
            {message.errorMessage}
        </Form>
    )
}

export default FormContainer