import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Pane = styled.div`
    flex: ${prop => prop.weight}; 
`;


const SplitScreen = ({ children, leftWeight, rightWeight }) => {
    const [left, right] = children; // two children component

    return (
        <Container>
            <Pane weight={leftWeight}>
                {left}
            </Pane>
            <Pane weight={rightWeight}>
                {right}
            </Pane>
        </Container>
    )
}

export default SplitScreen