import React from 'react';
import styled from 'styled-components';

const GridContainer = styled.div`
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-auto-rows: auto;
    gap: ${prop => prop.gap};

    @media screen and (min-width: ${prop => prop.mediaScreen}) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const GridLayout = ({ children, gap, mediaScreen }) => {
    return (
        <GridContainer gap={gap} mediaScreen={mediaScreen}>
            {children}
        </GridContainer>
    );
};

export default GridLayout;
