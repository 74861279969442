import React from 'react';
import styled, { keyframes } from 'styled-components';
import Text from '../Text/Text';
import PropTypes from 'prop-types';

const slideUp = keyframes`
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;

const AnimeText = styled.span`
  display: block;
  overflow: hidden;
  margin-bottom: ${prop => prop.marginBottom};
`;

const AnimatingSpan = styled.span`
    display: inline-block;
    animation-name: ${slideUp};
    animation-duration: ${prop => prop.delay};
    animation-timing-function: ease-in-out; /* Adjust the timing function for a smoother effect */
    opacity: 0;
    animation-fill-mode: forwards;
`;


const typing = keyframes`
    0% {
        width: 0;
    }
    30% {
        width: 30%;
    }
    60% {
        width: 60%;
    }
    90% {
        width: 90%;
    }
    100% {
        width: 100%;
    }
`;

const TypeWriter = styled.p`
    overflow: hidden; /* Ensures text is not visible until animated */
    white-space: nowrap; /* Keeps the text in one line */
    animation: ${typing} 0.9s steps(20); /* Adjust timing and steps as needed */
    margin-bottom: ${prop => prop.marginBottom};
`;

const SpaceHolder = styled.div`
  /* min-height: 150px; */
  min-height: ${(prop) => prop.spaceholder};
  
  @media screen and (min-width: 767px) {

  }
`;


const AnimatedText = ({ variant, children, delay = 200, marginBottom, visible, spaceholder='200px' }) => {
    if (variant === 'slideup') {
        return (
            <AnimeText marginBottom={marginBottom}>
                {children.props.text.split(' ').map((word, index) => (
                    <React.Fragment key={index}>
                        <AnimatingSpan style={{ animationDelay: `${index * 30}ms` }} delay={delay}>
                            <Text text={word} variant={children.props.variant} />
                        </AnimatingSpan>
                        {index < children.props.text.split(' ').length - 1 && ' '}
                    </React.Fragment>
                ))}
            </AnimeText>
        );
    } else if (variant === 'typewriter') {
        if (visible)
            return (
                <TypeWriter marginBottom={marginBottom}>
                    <Text text={children.props.text} variant={children.props.variant} />
                </TypeWriter>
            );
        else    
            return <SpaceHolder spaceholder={spaceholder}></SpaceHolder>
    }
};
AnimatedText.propTypes = {
    variant: PropTypes.oneOf(['slideup', 'typewriter']).isRequired,
    children: PropTypes.element.isRequired,
};

export default AnimatedText;
