import React from 'react';
import styled from 'styled-components';
import btnBorder from '../../assets/button-border.svg';
import PropTypes from 'prop-types';

const Btn = styled.a`
    font-weight: 400;
    font-family: Allroundergrotesktest, sans-serif;
    font-size: 1rem;
    position: relative;
    z-index: 1; /* Ensure text is above the border */
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: inherit;
    height: 100%;
    width: 100%;
`;

const BtnContainer = styled.div`
    width: 12.25rem;
    max-width: 12.25rem;
    border: none; /* Remove the default border */
    position: relative;
    overflow: hidden; /* Hide overflow from border */
    padding: 2rem 0rem;
    outline: none;
    cursor: pointer;
    
    .btn-secondary {
        border: 0.5px solid rgba(255, 255, 255, 0.2);
        z-index: 2;
        width: 12.25rem;
        max-width: 12.25rem;
        color: #fff;
        background-color: rgba(0, 0, 0, 0);
        justify-content: center;
        align-items: center;
        margin-top: 0;
        padding: 1.2rem 1.5rem 1.125rem;
        display: flex;
        position: relative;
        transition: border 0.4s ease-in-out;
        cursor: pointer;
    }

    .btn-secondary:hover {
        border: 0.5px solid #fff;
    }
`;

const BtnWrapper = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    transition: opacity 0.5s ease-in-out;
    ${BtnContainer}:hover & {
        opacity: 1;
    }
`;

const Button = ({ text, variant='primary', link, newTab=false }) => {
    if (variant == 'primary')
        return (
            <BtnContainer>
                <BtnWrapper src={btnBorder} alt="Button Border" />
                <Btn href={link} target={newTab ? '_blank' : ''}>
                    {text}
                </Btn>
            </BtnContainer>
        );
    else if (variant == 'secondary')
        return (
            <BtnContainer>
                <button className='btn-secondary'>{text}</button>
            </BtnContainer>
        );
};

Button.propTypes = {
    text: PropTypes.string.isRequired
}

export default Button;
