import { createContext, useRef, useState } from "react";

const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSelectedWorkVisible, setIsSelectedWorkVisible] = useState(0.4);
    const selectedWorkRef = useRef(null);
    
    return (
        <GlobalContext.Provider value={{
            isMenuOpen,
            setIsMenuOpen,
            isSelectedWorkVisible,
            setIsSelectedWorkVisible,
            selectedWorkRef,
        }}>
            {children}
        </GlobalContext.Provider>
    );
}

export { GlobalContext, GlobalContextProvider };