import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Text from '../Text/Text';
import Button from '../Button/Button';

const Container = styled.div`
    background-color: #151515;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    display: flex;
    position: relative;

`;

const BtnHolder = styled.div`
    @media screen and (min-width: 990px) {
        position: absolute;
        display: ${prop => prop.isHover ? 'block' : 'none'}; 
        margin-top: -19%;
        margin-left: 30%;
    }
`;

const ImgHolder = styled.img`
    margin-bottom: 1.5rem;
    width: 100%;
    height: 100%;
    transition: filter 0.4s ease-in-out;

    @media screen and (min-width: 990px) {
        &:hover {
            filter: brightness(0.5) blur(10px);
        }
        filter: ${prop => prop.isHover ? 'brightness(0.5) blur(10px)' : null}
    }
`;

const Card = ({ image, title, subTitle, titleVisible, link }) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <Container>
            <ImgHolder isHover={isHover} src={image} onPointerOver={() => setIsHover(true)} onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onPointerLeave={() => setIsHover(false)} />
            <Text
                variant='h4'
                text={title}
            />
            <br />
            <Text
                variant='p'
                text={subTitle}
            />
            <BtnHolder isHover={isHover} onMouseOver={() => setIsHover(true)} onPointerOver={() => setIsHover(true)}>
                <Button link={link} text='View Project' newTab={true} onClick={() => setIsHover(!isHover)} />
            </BtnHolder>
        </Container>
    );
};

Card.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired
};

export default Card;
