import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useState } from 'react';


const HeroText = styled.h1`
    font-size: 3rem;
    font-family: Archia webfont, sans-serif;
    @media screen and (min-width: 479px) {
        font-size: 4rem;
    }

    @media screen and (min-width: 767px) {
        font-size: 5rem;
    }

    @media screen and (min-width: 767px) {
        font-size: 5.625rem;
        font-weight: 400;
        line-height: 1;
    }
`;

const HeaderText1 = styled.h1`
    font-size: 2rem;
    font-family: Archia webfont, sans-serif;
    @media screen and (min-width: 479px) {
        font-size: 2.5rem;
    }

    @media screen and (min-width: 767px) {
        font-size: 3rem;
        font-weight: 400;
        line-height: 1.1;
    }
`;

const HeaderText2 = styled.h2`
    font-size: 1.5rem;
    font-family: Archia webfont, sans-serif;
    @media screen and (min-width: 479px) {
        font-size: 2rem;
        line-height: 1.2;
    }
`;

const HeaderText3 = styled.h3`
    font-size: 1.25rem;
    font-family: Archia webfont, sans-serif;
`;

const HeaderText4 = styled.h4`
    font-size: 1.125rem;
    font-family: Archia webfont, sans-serif;
    @media screen and (min-width: 479px) {
        font-size: 1.5rem;
        line-height: 1.2;
    }
`;

const SpecialText = styled.h2`
    font-size: 1.8rem;
    font-family: Archia webfont, sans-serif;
    @media screen and (min-width: 479px) {
        font-size: 3rem;
        font-weight: 500;
        line-height: 1.1;
    }
`;

const ParaText = styled.p`
    font-size: 1rem;
    font-family: Allroundergrotesktest, sans-serif;
    @media screen and (min-width: 479px) {
        font-weight: 400;
        line-height: 1.5;
    }

    @media screen and (min-width: 767px) {
        font-size: 1.125rem;
    }
`;

const ParaText2 = styled.p`
    font-size: .875rem;
    font-family: Allroundergrotesktest, sans-serif;
    line-height: 1.8;
`;

const ParaWorkProcessText = styled.p`
    font-size: 1rem;
    font-family: Allroundergrotesktest, sans-serif;
    @media screen and (min-width: 767px) {
        font-size: .875rem;
        line-height: 1.4;
        z-index: 2;
    }
`;


const Text = ({ text, variant, customClass }) => {
    switch (variant) {
        case 'heroText':
            return <HeroText className={customClass} >{text}</HeroText>
        case 'h1':
            return <HeaderText1 className={customClass} >{text}</HeaderText1>
        case 'h2':
            return <HeaderText2 className={customClass} >{text}</HeaderText2>
        case 'h3':
            return <HeaderText3 className={customClass} >{text}</HeaderText3>
        case 'h4':
            return <HeaderText4 className={customClass} >{text}</HeaderText4>
        case 'p':
            return <ParaText className={customClass} >{text}</ParaText>
        case 'p2':
            return <ParaText2 className={customClass} >{text}</ParaText2>
        case 'workprocess-p':
            return <ParaWorkProcessText className={customClass} >{text}</ParaWorkProcessText>
        case 'special':
            return <SpecialText className={customClass} >{text}</SpecialText>
    }
}

Text.propTypes = {
    text: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['heroText', 'h1', 'h2', 'h3', 'h4', 'p', 'p2', 'workprocess-p', 'special']),
}

export default Text