import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import SplitScreen from '../layout/SplitScreen';
import GridLayout from '../layout/GridLayout';
import Card from '../component/Card/Card';

import randImg from '../assets/rand.jpg';
import kabello from '../assets/kabello.jpg';
import writeologyx from '../assets/writeologyx.jpg';
import figmaland from '../assets/figmaland.jpg';
import shearStyle from '../assets/shearstyle.jpg';
import deerField from '../assets/deerfield.jpg';

import Text from '../component/Text/Text';
import Button from '../component/Button/Button';
import AnimateSlideUpText from '../component/AnimateSlideUpText/AnimateSlideUpText';
import AnimatedText from '../component/AnimatedText/AnimatedText';
// import { isInViewport } from '../helper/useViewport';

import cylinderVideo from '../assets/cylinder.mp4';
import rectangleVideo from '../assets/rectangle.webm';
import WorkFlow from '../component/WorkFlow/WorkFlow';
import { GlobalContext } from '../context/GlobalContext';
import useHelper from '../helper/useHelper';
import FormContainer from '../component/FormContainer/FormContainer';
import Testinomials from '../component/Testinomials/Testinomials';

const HeroWrapper = styled.header`
    max-width: 1580px;
    width: 90%;
    margin: 0 auto;
`;

const HeroContainer = styled.div`
    min-height: 100vh;
    /* padding-top: 1rem; */
    max-width: 1430px;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 8rem;
    margin-bottom: 4rem;
`;

const HeroBtnWrapper = styled.div`
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 27rem;
`;

const ServiceWrapper = styled.div`
    margin: 0 auto;   
    min-height: 70vh;

    .leftSide {
        min-width: 350px;
        max-width: 600px;
        padding-right: 2rem;
        margin-bottom: 2rem;
    }

    .rightSide {
        min-width: 350px;
        
        @media screen and (min-width: 874px) {
            display: flex;
            justify-content: flex-end;
            padding-top: 14rem;
            
        }
        
        @media screen and (min-width: 1500px) {
            padding-right: 15rem;

        }
    }
`;

const SelectedWork = styled.div`
    max-width: 1365px;
    margin-bottom: 8rem;

    .marginBot {
        margin-bottom: 6rem;
    }

    @media screen and (min-width: 874px) {
        margin-bottom: 10rem;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 15rem;
    }
`;

const Section = styled.section`
    max-width: 1430px;

    .video-background {
        position: absolute;
        width: 70%;
        max-width: 2000px;
        height: 50vh;
        overflow: hidden;
        margin-left: 5rem;
        margin-top: -3rem;
        z-index: -1;
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .customTime {
        max-width: 1100px;
        margin-bottom: 4rem;
        margin-top: 14rem;
    }

    @media screen and (min-width: 874px) {
        .customTime {
            max-width: 1100px;
            margin-bottom: 4rem;
            margin-top: 14rem;
        }

        .workflow {
            margin-left: 5rem;
        }

        .on-time {
            margin-bottom: 15rem;
        }
    }
    
    @media screen and (min-width: 1200px) {
        .workflow {
            margin-left: 12rem;
        }
    }
`;



const Home = () => {
    const elevateTxtRef = useRef(null);
    const customTimeRef = useRef(null);
    const workFlowRef = useRef(null);
    const formRef = useRef(null);
    const contactRef = useRef(null);
    const processRef = useRef(null);
    const workGridRef = useRef(null);

    const [isVisible, setIsVisible] = useState({
        elevateTxtRef: false,
        selectedWorkRef: false,
        customTimeRef: false,
        workFlowRef: false,
        formRef: false,
    });

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const { setIsSelectedWorkVisible, selectedWorkRef } = useContext(GlobalContext);
    const { scrollToDiv } = useHelper();

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array means this effect runs only once after initial render

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target === elevateTxtRef.current) {
                    setIsVisible((prevVisibility) => ({
                        ...prevVisibility,
                        elevateTxtRef: entry.isIntersecting && entry.intersectionRatio === 1.0,
                    }));
                }
            });
        }, options);

        if (elevateTxtRef.current) {
            observer.observe(elevateTxtRef.current);
        }

        return () => {
            if (elevateTxtRef.current) {
                observer.unobserve(elevateTxtRef.current);
            }

        };
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target === selectedWorkRef.current) {
                    setIsVisible((prevVisibility) => ({
                        ...prevVisibility,
                        selectedWorkRef: entry.isIntersecting && entry.intersectionRatio === 1.0,
                    }));
                    setIsSelectedWorkVisible(entry.isIntersecting && entry.intersectionRatio === 1.0 && 1);
                }
            });
        }, options);

        if (selectedWorkRef.current) {
            observer.observe(selectedWorkRef.current);
        }

        return () => {
            if (selectedWorkRef.current) {
                observer.observe(selectedWorkRef.current);
            }

        };
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target === workGridRef.current) {
                    if (entry.isIntersecting && entry.intersectionRatio === 1.0) {

                        console.log('grid visible')
                        setIsSelectedWorkVisible(1);
                    }
                }
            });
        }, options);

        if (workGridRef.current) {
            observer.observe(workGridRef.current);
        }

        return () => {
            if (workGridRef.current) {
                observer.observe(workGridRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target === customTimeRef.current) {
                    setIsVisible((prevVisibility) => ({
                        ...prevVisibility,
                        customTimeRef: entry.isIntersecting && entry.intersectionRatio === 1.0,
                    }));
                }
            });
        }, options);

        if (customTimeRef.current) {
            observer.observe(customTimeRef.current);
        }

        return () => {
            if (customTimeRef.current) {
                observer.observe(customTimeRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target === workFlowRef.current) {
                    setIsVisible((prevVisibility) => ({
                        ...prevVisibility,
                        workFlowRef: entry.isIntersecting && entry.intersectionRatio === 1.0,
                    }));
                }
            });
        }, options);

        if (workFlowRef.current) {
            observer.observe(workFlowRef.current);
        }

        return () => {
            if (workFlowRef.current) {
                observer.observe(workFlowRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target === formRef.current) {
                    setIsVisible((prevVisibility) => ({
                        ...prevVisibility,
                        formRef: entry.isIntersecting && entry.intersectionRatio === 1.0,
                    }));
                }
            });
        }, options);

        if (formRef.current) {
            observer.observe(formRef.current);
        }

        return () => {
            if (formRef.current) {
                observer.observe(formRef.current);
            }
        };
    }, []);


    return (
        <HeroWrapper>
            <HeroContainer>
                <AnimateSlideUpText visible={true} text='Hi, I’m  Soyas.  A creative' delay={0.2} variant='hero' />
                <AnimateSlideUpText visible={true} text='Web Developer to build your' delay={0.4} variant='hero' />
                <AnimateSlideUpText visible={true} text=' website & web app' delay={0.6} variant='hero' />
                <HeroBtnWrapper>
                    <div onClick={() => scrollToDiv(contactRef)}>
                        <Button
                            text="Send me a message"
                        />
                    </div>
                    <Button
                        text="View Resume"
                        link="https://docs.google.com/document/d/1oybHhV9vgKyi1vkYDoxaH4P744rP4V3x/edit?usp=sharing&ouid=114751073313990379931&rtpof=true&sd=true"
                    />
                </HeroBtnWrapper>
            </HeroContainer>
            <ServiceWrapper>
                <SplitScreen leftWeight='1' rightWeight='0.4'>
                    <div className='leftSide' ref={elevateTxtRef}>
                        <AnimateSlideUpText text="Elevate Your Online Presence and Grow Your Business" delay={0.2} variant='title' marginBottom='2rem' visible={isVisible.elevateTxtRef}  />
                        <Text 
                            variant='p2'
                            text="Transform your vision into a high-performance website that captivates your audience and boosts conversions. With a focus on exceptional design and tailored development, I'll align your online presence with business objectives. Elevate your success with a uniquely crafted digital experience"
                        />
                    </div>
                    <div className='rightSide'>
                        <div onClick={() => scrollToDiv(processRef)}>
                            <Button 
                                text='View my process'
                            />
                        </div>
                    </div>
                </SplitScreen>
            </ServiceWrapper>
            
            <SelectedWork>
                <div ref={selectedWorkRef} className='marginBot'>
                    <AnimatedText variant='typewriter' delay={500} marginBottom='5rem' visible={isVisible.selectedWorkRef} spaceholder='50px'>
                        <Text 
                            text='Selected works'
                            variant='h2'
                        />
                    </AnimatedText>
                </div>
                <GridLayout gap='3rem' mediaScreen='767px'>
                    <Card 
                        titleVisible={true}
                        image={kabello}
                        title='Kabello Studio'
                        subTitle='Let us help you establish a strong and impactful online presence that drives growth and success.'
                        link="https://kabello-website.webflow.io/"
                    />
                    <Card 
                        titleVisible={true}
                        image={writeologyx}
                        title='Writeology X'
                        subTitle='Dive into the latest trends, cutting-edge technologies, and expert analysis in the ever-evolving world of tech.'
                        link="https://writeologyx-db41a0.webflow.io/"
                    />
                    <Card 
                        titleVisible={true}
                        image={figmaland}
                        title='FigmaLand'
                        subTitle='Accept payments from anywhere with ease, gain access to capital and use simple business tools to grow your business.'
                        link="https://figmaland.soyaslimbu.com/"
                    />
                    <Card 
                        titleVisible={true}
                        image={shearStyle}
                        title='Shear & Style'
                        subTitle='Our team of experienced hairstylists is dedicated to providing you with the best possible hair care experience.'
                        link="https://soyaslimbu07.wixsite.com/shearstyle"
                    />
                    <Card 
                        titleVisible={true}
                        image={deerField}
                        title='Deer Field'
                        subTitle='Deerfield Farm is a family-owned farm that provide the freshest and healthiest produce to our customers and the community.'
                        link="https://soyaslimbu07.wixsite.com/deerfieldfarm/"
                    />
                </GridLayout>
            </SelectedWork>
            {/* <Section>
                <Testinomials />
            </Section> */}
            <Section>
                <div className='on-time'>
                    <div className="video-background">
                        <video autoPlay loop muted playsInline>
                            <source src={cylinderVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div ref={customTimeRef} className='customTime'>
                        <AnimateSlideUpText 
                            text="On time and affordable Custom Powerful sites & web app" 
                            delay={0.2} 
                            variant='title' 
                            marginBottom='2rem' 
                            visible={isVisible.customTimeRef}  
                            spaceholder='145px'
                        />
                        <div style={{marginBottom:'2rem'}}>
                            <Text 
                                variant='p2'
                                text="Get the web presence you need without breaking the bank. My Custom sites & Web App are delivered on time and at an affordable price. Bring your dream web design to life with my smart and creative approach. Let's make it a reality."
                            />
                        </div>
                        <Button 
                            text='Schedula a call'
                        />
                    </div>
                </div>
            </Section>
            
            <Section style={{maxWidth:'1350px',marginBottom: '20rem'}} ref={processRef}>
                <SplitScreen leftWeight='1' rightWeight='1'>
                    <div ref={workFlowRef} className='customTime'>
                        <AnimateSlideUpText 
                            text="My work process" 
                            delay={0.1} 
                            variant='title' 
                            marginBottom='2rem' 
                            visible={isVisible.workFlowRef}  
                            spaceholder='87px'
                        />
                        <div style={{marginBottom:'2rem'}}>
                            <Text 
                                variant='p2'
                                text="Get a glimpse into the way I approach projects as a Web Developer. From initial planning and design to coding and testing, follow along as I walk you through my work process and the steps I take to bring a project to life."
                            />
                        </div>
                        <div onClick={() => scrollToDiv(contactRef)}>
                            <Button
                                text="Send me a message"
                            />
                        </div>
                    </div>
                    <div className='workflow'>
                        <GridLayout gap='2rem' mediaScreen='767px'>
                            <WorkFlow
                                title="Planning/Introductory call"
                                description="This step involves identifying the goals and objectives of the website, researching the target audience and competitors, and also defining the scope and requirement of the project."
                            />
                            <WorkFlow
                                title="Design"
                                description="This stage involves creating a visual design for the website, including the layout, color scheme, and branding. This may involve creating wireframes or mockups to visualize the structure of the website."
                            />
                            <WorkFlow
                                title="Development"
                                description="This step is where I develop a detailed, fully functional website or web app by coding. This steps may also involve creating custom functionality, as well as integrating with third-party services/platforms."
                            />
                            <WorkFlow
                                title="Launch/Maintenance"
                                description="This process involves making the Website accessible to the public. It also involves regular update and maintenance of the website to ensure that it remains up to date and functional."
                            />
                        </GridLayout>
                    </div>
                </SplitScreen>
                <div className="video-background">
                    <video autoPlay loop muted playsInline>
                        <source src={rectangleVideo} type="video/webm" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </Section>
            <Section ref={contactRef}>
                <SplitScreen leftWeight='1' rightWeight={screenWidth>=1400 ? '1.4' : null}>
                    <div ref={formRef}>
                        <AnimateSlideUpText 
                            text="Lets start your next successful project" 
                            delay={0.2} 
                            variant='title' 
                            marginBottom='2rem' 
                            visible={isVisible.formRef}  
                            spaceholder='145px'
                        />
                        <div style={{marginBottom:'2rem'}}>
                            <Text 
                                variant='p2'
                                text="Secure the online presence you desire without exceeding your budget. My expertise lies in crafting web applications and websites promptly and affordably, bringing your vision to life with a smart and creative approach. Let's turn your dream project into reality together."
                            />
                        </div>
                    </div>
                    <FormContainer />
                </SplitScreen>
            </Section>
        </HeroWrapper>
    )
}

export default Home