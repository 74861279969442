import React from 'react'
import styled from 'styled-components';
import btnBorder from '../../assets/workflow.svg';
import Text from '../Text/Text';

const WorkFlow = ({ title, description }) => {
    const Btn = styled.a`
        font-weight: 400;
        font-family: Allroundergrotesktest, sans-serif;
        font-size: 1rem;
        position: relative;
        z-index: 1; /* Ensure text is above the border */
        display: flex;
        /* justify-content: center; */
        align-items: center;
        text-decoration: none;
        color: inherit;
        height: 100%;
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 1rem;
    `;

    const BtnContainer = styled.div`
        width: 16.25rem;
        border: none; /* Remove the default border */
        position: relative;
        overflow: hidden; /* Hide overflow from border */
        padding: 2rem 1rem;
    `;

    const BtnWrapper = styled.img`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        transition: opacity 0.5s ease-in-out;
        ${BtnContainer}:hover & {
            opacity: 1;
        }
    `;

    const Para = styled.div`
        margin-bottom: 2rem;
    `;

    const Title = styled.div`
        .title-p {
            font-weight: 600;
        }
    `;

    return (
        <div>
            <BtnContainer>
                <BtnWrapper src={btnBorder} alt="Button Border" />
                <Btn href="#">
                    <Title>
                        <Text 
                            variant='p'
                            text={title}
                            customClass='title-p'
                        />    
                    </Title>
                </Btn>
                <Para>
                    <Text 
                        variant='workprocess-p'
                        text={description}
                    />
                </Para>
            </BtnContainer>
        </div>
    )
}

export default WorkFlow