import React from 'react'
import styled from 'styled-components'

import githubIcon from '../../assets/github.png';
import linkedinIcon from '../../assets/linkedin.png';

const Footer = () => {
    const FooterWrapper = styled.footer`
        border-top: 1px solid #282828;
        padding: 5rem 2rem 2rem 2rem;
    `;

    const Section = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;

        .circle {
            width: 13px;
            height: 13px;
            border-radius: 50%; /* Makes the div round */
            background-color: #24f373;
            margin-right: 1rem;
        }
    `;

    return (
        <FooterWrapper>
            <Section>  
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <a href='https://github.com/soyas07/' target='_'><img src={githubIcon} width='25px' height='25px' /></a>
                    <a href='https://www.linkedin.com/in/soyaslimbu/' target='_' ><img src={linkedinIcon} width='30px' height='30px' style={{marginLeft:'2rem'}} /></a>
                </div>
            </Section>
            <Section>
                <div className='circle'></div>
                <em style={{fontWeight:'200'}}>Currently available for new projects</em>
            </Section>
        </FooterWrapper>
    )
}

export default Footer